<template>
	<S2SForm title="Search PAN">
		<S2SCard wrap align-center fluid>
			<v-form ref="form">
				<v-row>
					<v-col cols="12" md="6">
						<v-text-field
							v-model="panData.panStart"
							label="PAN Start"
							clearable
							autofocus
							type="text"
							:loading="status.loading"
							:rules="[v => !!v || 'PAN Start is required']"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="6">
						<v-text-field
							v-model="panData.panEnd"
							label="PAN End"
							clearable
							type="text"
							:loading="status.loading"
							:rules="[v => !!v || 'PAN End is required']"
						></v-text-field>
					</v-col>
				</v-row>
				<v-row v-show="advanced">
					<v-col cols="12" md="4">
						<v-dialog ref="dialogStart" v-model="startDate" :return-value.sync="panData.startDate" persistent width="290px">
							<template v-slot:activator="{ on }">
								<v-text-field v-model="panData.startDate" label="Start Date" append-icon="event" readonly v-on="on" clearable></v-text-field>
							</template>
							<v-date-picker v-model="panData.startDate" scrollable>
								<v-spacer></v-spacer>
								<v-btn text color="primary" @click="startDate = false">Cancel</v-btn>
								<v-btn text color="primary" @click="$refs.dialogStart.save(panData.startDate)">OK</v-btn>
							</v-date-picker>
						</v-dialog>
					</v-col>
					<v-col cols="12" md="4">
						<v-dialog ref="dialogEnd" v-model="endDate" :return-value.sync="panData.endDate" persistent width="290px">
							<template v-slot:activator="{ on }">
								<v-text-field v-model="panData.endDate" label="End Date" append-icon="event" readonly v-on="on" clearable></v-text-field>
							</template>
							<v-date-picker v-model="panData.endDate" scrollable>
								<v-spacer></v-spacer>
								<v-btn text color="primary" @click="endDate = false">Cancel</v-btn>
								<v-btn text color="primary" @click="$refs.dialogEnd.save(panData.endDate)">OK</v-btn>
							</v-date-picker>
						</v-dialog>
					</v-col>
					<v-col cols="12" md="4">
						<v-text-field v-model="panData.amount" label="Amount" clearable type="text" :loading="status.loading"></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" class="advanced">
						<span class="ma-0 pointer" @click="advanced = !advanced" v-if="!advanced">+ show more</span>
						<span class="ma-0 pointer" @click="advanced = !advanced" v-if="advanced">- show less</span>
					</v-col>
				</v-row>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="accent" @click="searchPan(panData)">Search <v-icon class="ml-2">search</v-icon></v-btn>
				</v-card-actions>
			</v-form>
		</S2SCard>

		<v-divider class="mt-5 mb-5"></v-divider>

		<v-data-table :headers="transactionHeaders" :items="panTransactions" class="elevation-1" :options.sync="pagination" :loading="status.loading">
			<template v-slot:item.amount="{ item }">
				{{ item.amount }}
			</template>
			<template v-slot:item.terminalTime="{ item }">
				{{ formatDate(item.terminalTime) }}
			</template>
			<template v-slot:item.status="{ item }">
				{{ getStatus(item.status) }}
			</template>
			<template v-slot:item.slip="{ item }">
				<v-btn @click="downloadSlip(item.transactionReference)"><v-icon>mdi-download</v-icon></v-btn>
			</template>
			<template v-slot:item.copy="{ item }">
				<v-btn @click="copyText(item)"><v-icon>mdi-content-copy</v-icon></v-btn>
			</template>
		</v-data-table>
	</S2SForm>
</template>

<script>
import Vue from "vue";
import { Utils } from "@/utils";

export default Vue.extend({
	name: "SearchPan",

	computed: {
		panTransactions: function() {
			return this.$store.state.devices.panTransactions;
		},
		status: function() {
			return this.$store.state.devices.status;
		}
	},

	data: function() {
		return {
			showResults: false,
			startDate: false,
			endDate: false,
			advanced: false,
			panData: {},

			pagination: {
				descending: true,
				itemsPerPage: 10
			},

			transactionHeaders: [
				{ text: "Amount", value: "amount" },
				{ text: "Terminal ID", value: "terminalId" },
				{ text: "Shop Code", value: "shopCode" },
				{ text: "Organisation Name", value: "orgName" },
				{ text: "Issuer", value: "issuer" },
				{ text: "PAN", value: "pan" },
				{ text: "Terminal Time", value: "terminalTime" },
				{ text: "Acquirer Code", value: "acquirerCode" },
				{ text: "Acquirer Description", value: "acquirerDescription" },
				{ text: "Status", value: "status" },
				{ text: "Download Slip", value: "slip" },
				{ text: "Copy", value: "copy" }
			]
		};
	},

	methods: {
		async searchPan(panData) {
			const form = this.$refs["form"];
			const valid = await form.validate();
			if (!valid) return;
			this.$store.dispatch("devices/fetchPAN", panData);
		},
		downloadSlip(item) {
			this.$store.dispatch("devices/downloadSlip", item);
		},
		copyText(item) {
			const ref = `ref: ${item.transactionReference}`;
			const text = `${this.formatDate(item.terminalTime)} for ${item.amount} at ${item.orgName} (${item.shopCode})`;
			const copyText = `${ref}\n\r${text}`;
			const el = document.createElement("textarea");
			el.value = copyText;
			el.setAttribute("readonly", "");
			el.style.position = "absolute";
			el.style.left = "-9999px";
			document.body.appendChild(el);
			el.select();
			document.execCommand("copy");
			document.body.removeChild(el);
		},
		formatCurrency(value) {
			return Utils.formatText(value, Utils.TextType.CURRENCY);
		},
		formatDate(val) {
			return Utils.formatText(val, Utils.TextType.DATE_TIME);
		},
		getStatus(id) {
			let status = "No status";
			switch (id) {
				case 100:
					status = "Invalid";
					break;
				case 200:
					status = "Result Received";
					break;
				case 250:
					status = "Card Error";
					break;
				case 251:
					status = "No Amount";
					break;
				case 252:
					status = "Amount Mismatch";
					break;
				case 300:
					status = "Success";
					break;
				case 400:
					status = "Recharge Failed";
					break;
				case 500:
					status = "Cancelled";
					break;
			}
			return status;
		}
	}
});
</script>

<style scoped>
.pointer {
	cursor: pointer;
}

.advanced {
	color: #777;
	font-size: 12px;
}
</style>
